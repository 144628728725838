import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'

import { getJobStatus } from './api'

function FileProcessingStatus({ jobId }) {
  const [status, setStatus] = useState('Processing')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const res = await getJobStatus(jobId)
        setStatus(res.status)
        if (res.status !== 'Processing') {
          clearInterval(intervalId)
        }
      } catch (error) {
        console.error(error)
      }
    }, 1500)

    return () => clearInterval(intervalId)
  }, [jobId])

  useEffect(() => {
    if (status !== 'Processing') {
      setIsLoading(false)
    }
  }, [status])

  return (
    <div>
      File processing status: {status}
      {isLoading && <Spinner />}
    </div>
  )
}

FileProcessingStatus.propTypes = {
  jobId: PropTypes.string.isRequired,
}

export default FileProcessingStatus
