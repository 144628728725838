import React, { useState } from 'react'
import { extractKnowledgeFromUrl, deleteEmbeddings } from './api'

const UrlInput = () => {
  const [url, setUrl] = useState('')

  const handleInputChange = (event) => {
    setUrl(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Submitted URL:', url)
    extractKnowledgeFromUrl(url)
  }

  const handleRefresh = () => {
    deleteEmbeddings()
  }

  return (
    <div className='mb-10'>
      <form onSubmit={handleSubmit} className='h-full p-6 bg-white rounded shadow-md'>
        <input
          type='text'
          value={url}
          onChange={handleInputChange}
          placeholder='Enter a URL'
          className='w-full p-2 mb-4 border rounded'
        />
        <div className='flex justify-between'>
          <button
            type='submit'
            className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700'
          >
            Submit
          </button>
          <button
            type='button'
            onClick={handleRefresh}
            className='px-4 py-2 text-white bg-green-500 rounded hover:bg-green-700'
          >
            Delete Embeddings
          </button>
        </div>
      </form>
    </div>
  )
}

export default UrlInput
