import React, { useState } from 'react'
import { extractKnowledgeFromFile } from './api'
import FileProcessingStatus from './FileProcessingStatus'

const FileInput = () => {
  const [files, setFiles] = useState([])
  const [jobIds, setJobIds] = useState([])

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files)
    setFiles(selectedFiles)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (files.length === 0) {
      alert('Please select at least one file before uploading')
      return
    }

    try {
      const formData = new FormData()
      files.forEach((file) => {
        formData.append('files', file)
      })

      extractKnowledgeFromFile(formData)
        .then((res) => {
          setJobIds(res)
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='mb-10 mr-20'>
      <form onSubmit={handleSubmit} className='p-6 bg-white rounded shadow-md'>
        <input
          type='file'
          onChange={handleFileChange}
          className='w-full p-2 mb-4 border rounded'
          multiple
        />
        {files.length > 0 && (
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        )}
        <div className='flex justify-end'>
          <button
            type='submit'
            className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700'
          >
            Upload
          </button>
          {jobIds.length > 0 && (
            <div>
              {jobIds.map((jobId, index) => (
                <FileProcessingStatus key={index} jobId={jobId} />
              ))}
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default FileInput
