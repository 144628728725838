import React, { useState } from 'react'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'

import awsExports from './aws-exports'
import { TextField, Button, Switch } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { performHybridSearch, performRag } from './api'
Amplify.configure(awsExports)

import FileInput from './FileInput'
import UrlInput from './UrlInput'

function App() {
  const [query, setQuery] = useState('')
  const [answer, setAnswer] = useState('')
  const [searchResults, setSearchResults] = useState('')
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [useVicuna, setUseVicuna] = useState(false)
  document.body.style.backgroundColor = 'gray'
  let timerId = null
  const debouncedHandler = (event) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      const newQuery = event.target.value
      setQuery(newQuery)
      performHybridSearch(newQuery).then((results) => {
        setSearchResults(results)
      })
      setSearchPerformed(true)
    }, 666)
  }
  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-200'>
      <div className='flex items-center mb-10'>
        <img src='fireside.png' alt='Logo' className='w-20 mr-4' />
        <h1 className='text-6xl font-bold'>
          <span className='text-red-500'>Fireside</span>
          <span className='text-gray-500'>AI</span>
        </h1>
      </div>
      <div className='grid grid-cols-2 items-stretch'>
        <FileInput />
        <UrlInput />
      </div>
      <div className='w-full grid gap-4 grid-cols-2 pl-6 pr-6'>
        <TextField
          className='col-start-1'
          id='standard-basic'
          label='Enter Query'
          variant='standard'
          onChange={debouncedHandler}
        />
        <Button
          className='col-start-2'
          variant='outlined'
          onClick={() => performRag(query, useVicuna).then((answer) => setAnswer(answer))}
        >
          Query
        </Button>
        <FormControlLabel
          control={
            <Switch
              className='col-start-3'
              checked={useVicuna}
              onChange={() => setUseVicuna(!useVicuna)}
              name='loading'
              color='primary'
            />
          }
          label='Use Vicuna'
        />
        <div className='col-start-1 col-end-1 row-start-4 row-end-4 h-[200px] overflow-auto'>
          {searchPerformed ? (
            searchResults && Object.keys(searchResults).length > 0 ? (
              <div>{searchResults}</div>
            ) : (
              <div>No Results Found</div>
            )
          ) : null}
        </div>
        <div className='col-start-2 col-end-2 row-start-4 row-end-4 h-[200px] overflow-auto'>
          {answer}
        </div>
      </div>
    </div>
  )
}

export default App
